import React, { useEffect } from "react"
import Layout from "../components/layout"
import { navigate } from "gatsby"

const RecitalBook = () => {
  useEffect(() => {
    navigate("/recital_book_23-24.pdf")
  })
  return (
    <Layout>
      <div className="w-full flex justify-center items-center flex-col pt-10">
        <h2>Check your downloads for the recital program PDF.</h2>
      </div>
    </Layout>
  )
}

export default RecitalBook
